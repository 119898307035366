import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "./BTable2.css";
import ToolkitProvider, {
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const { ExportCSVButton } = CSVExport;

const BTable = ({
  data,
  columns,
  filterFactory,
  paginationFactory,
  rowEvents,
  keyField = "id",
  selectRow,
  downloadReport = false,
}) => {
  return (
    <ToolkitProvider
      keyField="id"
      data={data}
      columns={columns}
      exportCSV={{ onlyExportFiltered: true, exportAll: false }}
      search
    >
      {(props) => (
        <div className="table-scroll">
          {downloadReport ? (
            <div className="mb-5">
              <ExportCSVButton className="export__btn" {...props.csvProps}>
                Download Report
              </ExportCSVButton>
            </div>
          ) : (
            ""
          )}
          {/* <SearchBar {...props.searchProps} /> */}
          <BootstrapTable
            {...props.baseProps}
            responsive
            striped
            bordered
            hover
            keyField={keyField}
            data={data}
            columns={columns}
            filter={filterFactory}
            pagination={paginationFactory}
            noDataIndication="No record found..."
            rowEvents={rowEvents}
            selectRow={selectRow}
          />
        </div>
      )}
    </ToolkitProvider>
  );
};

export default BTable;
