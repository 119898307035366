import React, { useState, useEffect } from "react";
import BTable from "../../components/BtTable/BtTable";
import "./ViewUser.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  textFilter,
  selectFilter,
  numberFilter,
  Comparator,
} from "react-bootstrap-table2-filter";
import { useNavigate } from "react-router";
import { get } from "../../utils/api";

const selectOptions = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  NEW: "New",
  RENEW: "Renew",
};

function ViewUser() {
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const columns = [
    {
      dataField: "name",
      text: "Name",
      filter: textFilter(),
    },
    {
      dataField: "address",
      text: "Address",
    },
    {
      dataField: "age",
      text: "Age",
      headerAttrs: { width: 120 },
      attrs: { width: 50 },
      filter: numberFilter({
        comparators: [Comparator.GT],
        defaultValue: { comparator: Comparator.GT },
      }),
    },
    {
      dataField: "cardNumber",
      text: "Card Number",
      filter: textFilter(),
    },
    {
      dataField: "phoneNumber",
      text: "Phone",
      filter: textFilter(),
      headerAttrs: { width: 140 },
    },
    {
      dataField: "kbNumber",
      text: "KB Number",
    },
    {
      dataField: "status",
      text: "Status",
      headerAttrs: { width: 120 },
      formatter: (cell) => selectOptions[cell],
      filter: selectFilter({
        options: selectOptions,
      }),
    },
    {
      dataField: "expiryDate",
      text: "Expiry Date",
      headerAttrs: { width: 130 },
      formatter: (cell) => {
        let dateObj = cell;
        if (typeof cell !== "object") {
          dateObj = new Date(cell);
        }
        if (cell === null) {
          return;
        }
        return `${("0" + dateObj.getDate()).slice(-2)}/${(
          "0" +
          (dateObj.getMonth() + 1)
        ).slice(-2)}/${dateObj.getFullYear()}`;
      },
    },
    {
      dataField: "edit",
      text: "Edit",
      sort: false,
      headerAlign: "center",
      formatter: rankFormatter,
      headerAttrs: { width: 90 },
      attrs: { width: 50 },
    },
  ];

  function rankFormatter(cell, row) {
    const editEvent = (row) => {
      navigate(`/registerUser/${row.userId}/${row.status}`);
    };

    return (
      <div
        style={{
          textAlign: "center",
          lineHeight: "normal",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <span
          onClick={() => editEvent(row)}
          style={{ color: "blue", cursor: "pointer" }}
        >
          Edit
        </span>
      </div>
    );
  }

  const getAllUsers = async () => {
    setIsLoading(true);
    try {
      let res = await get("/User/GetUsers");
      if (res && res.data.length) {
        setSearchData(res.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <>
      <div class="text-center pt-5">
        <h3>View Users</h3>
      </div>
      {isLoading ? (
        <div class="mt-5 d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div class="p-5 table-responsive" id="btTable">
          <BTable
            keyField="userId"
            data={searchData}
            columns={columns}
            filterFactory={filterFactory()}
            paginationFactory={paginationFactory()}
            downloadReport={false}
          />
        </div>
      )}
    </>
  );
}
export default ViewUser;
