import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import RegisterUsers from "./pages/Users/RegisterUser";
import ViewUsers from "./pages/Users/ViewUser";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import SignIn from "./pages/Login/Login";
import SMS from "./pages/SMS/Sms";

// import the library
import { library } from "@fortawesome/fontawesome-svg-core";

// import your icons
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

function App() {
  return (
    <React.Fragment>
      <Navbar />
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/home" element={<Home />} />
          <Route
            path="/registerUser/:userId?/:status?"
            element={<RegisterUsers />}
          />
          <Route path="/viewUser" element={<ViewUsers />} />
          <Route path="/sms" element={<SMS />} />
        </Route>
      </Routes>
      <Footer />
    </React.Fragment>
  );
}

export default App;
library.add(fab, fas, far);
