import axios from "axios";

export const api = axios.create({
  baseURL: "https://dev.hnrbms.in/api",
});

export const post = async (endPoint, data) => {
  //https://dev.hnrbms.in/api/User/RegisterUser
  try {
    const resp = await axios.post(`${api.defaults.baseURL}${endPoint}`, data, {
      headers: { "Access-Control-Allow-Origin": "*" },
    });
    return resp;
  } catch (err) {
    console.error("Error from POST call of ", endPoint);
    return err;
  }
};

export const get = async (endPoint) => {
  try {
    const resp = await axios.get(`${api.defaults.baseURL}${endPoint}`, {
      headers: { "Access-Control-Allow-Origin": "*" },
    });
    return resp;
  } catch (err) {
    console.error("Error from GET call of ", endPoint);
    return err;
  }
};
