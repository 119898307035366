import React, { useState, useEffect } from "react";
import * as bootstrap from "bootstrap";
import Toast from "../../components/Toast/Toast";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/Auth";

function Login() {
  const authData = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    userName: "",
    password: "",
  });

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData((data) => ({
      ...data,
      [id]: value,
    }));
  };

  useEffect(() => {
    var isLoggedIn = false;
    if (authData) {
      isLoggedIn = JSON.parse(authData).isLoggedIn;
    }
    if (isLoggedIn) {
      sessionStorage.clear();
    }
  }, [authData]);

  const handleLogin = (event) => {
    event.preventDefault();
    if (formData.userName === "admin" && formData.password === "bms$123") {
      const sessionData = { userName: "Admin", isLoggedIn: true };
      sessionStorage.setItem("userInfo", JSON.stringify(sessionData));
      navigate("/viewUser");
    } else {
      let toastEl = document.getElementById("errorToast");
      let toast = new bootstrap.Toast(toastEl, { autohide: false });
      toast.show();
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-header">
              <h3 className="text-center">Login</h3>
            </div>
            <div className="card-body">
              <form onSubmit={handleLogin}>
                <div className="form-group">
                  <label htmlFor="userName">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    id="userName"
                    onChange={handleInputChange}
                    placeholder="Enter username"
                    value={formData.userName}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    onChange={handleInputChange}
                    value={formData.password}
                    className="form-control"
                    id="password"
                    placeholder="Enter password"
                    required
                  />
                </div>
                <div className="form-group text-center">
                  <button type="submit" className="btn btn-primary">
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Toast message="Invalid username/pssword" id={"errorToast"} />
    </div>
  );
}

export default Login;
