function Footer() {
  return (
    <div class="container mt-5">
      <footer class="py-5">
        <div class="py-4 my-4 border-top">
          © 2023 BMS HNR. All rights reserved.
        </div>
      </footer>
    </div>
  );
}

export default Footer;
