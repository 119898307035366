import React, { useState, useEffect } from "react";
import BTable from "../../components/BtTable/BtTable";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  textFilter,
  selectFilter,
  numberFilter,
  Comparator,
} from "react-bootstrap-table2-filter";
import { useNavigate } from "react-router";
import { get } from "../../utils/api";
import * as bootstrap from "bootstrap";
import Toast from "../../components/Toast/Toast";

const selectedArr = [];

const selectOptions = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

function SMS() {
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPh, setSelectedPh] = useState([]);

  const columns = [
    {
      dataField: "name",
      text: "Name",
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: "age",
      text: "Age",
      headerAttrs: { width: 120 },
      filter: numberFilter({
        comparators: [Comparator.GT],
        defaultValue: { comparator: Comparator.GT },
      }),
    },
    {
      dataField: "cardNumber",
      text: "Card Number",
      filter: textFilter(),
    },
    {
      dataField: "kbNumber",
      text: "KB Number",
    },
    {
      dataField: "phoneNumber",
      text: "Phone",
      filter: textFilter(),
    },
    {
      dataField: "status",
      text: "Status",
      headerAttrs: { width: 170 },
      formatter: (cell) => selectOptions[cell],
      filter: selectFilter({
        options: selectOptions,
      }),
    },
  ];

  const getAllUsers = async () => {
    setIsLoading(true);
    try {
      let res = await get("/User/GetUsers");
      if (res && res.data.length) {
        setSearchData(res.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedPh((selectedPh) => [...selectedPh, row.phoneNumber]);
      return;
    }

    const index = selectedPh.indexOf(row.phoneNumber);
    if (index > -1) {
      selectedPh.splice(index, 1);
    }
    setSelectedPh(selectedPh);
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const resArr = [];
    if (isSelect) {
      searchData.map((row, index) => {
        setSelectedPh((selectedPh) => [...selectedPh, row.phoneNumber]);
        resArr.push(row.userId);
      });
    } else {
      setSelectedPh([]);
    }
    return resArr;
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    console.log(selectedPh);
    let toastEl = document.getElementById("errorToast");
    let toast = new bootstrap.Toast(toastEl, { autohide: false });
    toast.show();
  };

  return (
    <>
      <div class="text-center pt-5">
        <h3>SMS</h3>
      </div>
      {isLoading ? (
        <div class="mt-5 d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div class="p-5 table-responsive" id="btTable">
          <BTable
            keyField="userId"
            data={searchData}
            columns={columns}
            filterFactory={filterFactory()}
            paginationFactory={paginationFactory()}
            downloadReport={false}
            selectRow={selectRow}
          />
        </div>
      )}

      <form class="container p-3 border mt-5" onSubmit={handleSubmitForm}>
        <div class="form-group">
          <div>
            <label for="address">SMS Text</label>
            <textarea
              rows={4}
              // onChange={handleInputChange}
              class="form-control"
              id="address"
              // value={data.address}
              required
            ></textarea>
          </div>
        </div>

        <button
          class="btn btn-primary"
          disabled={selectedPh.length > 0 ? false : true}
          type="submit"
        >
          {false ? (
            <span
              class="spinner-border spinner-border-sm mr-1"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            ""
          )}
          Send SMS
        </button>

        <button class="btn btn-light ml-3">Clear</button>
      </form>
      <Toast message={`SMS sent successfully.`} id={"successToast"} />
      <Toast message="Error occured." id={"errorToast"} />
    </>
  );
}
export default SMS;
