import React from "react";
import "./Home.css";
import background from "../../utils/images/mountain.jpg";
import f1 from "../../utils/images/tree3.png";

function Home() {
  return (
    <React.Fragment>
      <div className="wrapper">
        <div className="image-group">
          <img src={background} className="background" />
          <img src={f1} className="foreground1" />
          <h1 className="title">ಬಿ. ಎಮ್. ಎಸ್ ಸಂಯೋಜಿತ</h1>
        </div>
        <div className="footer-top">
          <div className="logo">
            <h3>ಬಿ. ಎಮ್. ಎಸ್ ಸಂಯೋಜಿತ</h3>
          </div>
          <div className="address">
            <b className="p-b-5">ವಿಳಾಸ</b>
            <div className="m-t-10">
              <p>ಕರ್ನಾಟಕ ರಾಜ್ಯ ಕಟ್ಟಡ ನಿರ್ಮಾಣ ಮತ್ತು ಕಾಮಗಾರಿ ಮಜದೂರ್ ಸಂಘ</p>
              <p>( ಬಿ. ಎಮ್. ಎಸ್ ಸಂಯೋಜಿತ ) ಹೊನ್ನಾವರ ತಾಲೂಕಾ ಘಟಕ</p>
              <p>ಬಸ್ಟ್ಯಾಂಡ್ ಬಳಿ, ಕಿಂತಾಲಕೇರಿ ರಸ್ತೆ ಮರಬಳ್ಳಿ ಕಂಪೌಂಡ್</p>
              <p>ಹೊನ್ನಾವರ (ಉ. ಕ) - 581334</p>
            </div>
          </div>
          <div className="contact">
            <b className="p-b-5">ಸಂಪರ್ಕದಲ್ಲಿರಲು</b>
            <div className="m-t-10">
              <p>ದೂರವಾಣಿ: +91 7204187673</p>
              <p>ಇಮೇಲ್: bmsofc.honnavar@gmail.com</p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Home;
