import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../utils/Auth";

const ProtectedRoutes = () => {
  const authData = useAuth();
  var isLoggedIn = false;
  if (authData) {
    isLoggedIn = JSON.parse(authData).isLoggedIn;
  }

  return isLoggedIn ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
