import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { get, post } from "../../utils/api";
import * as bootstrap from "bootstrap";
import Toast from "../../components/Toast/Toast";

const inititlState = {
  name: "",
  phoneNumber: "",
  address: "",
  cardNumber: "",
  kbNumber: "",
  userId: 0,
  age: 0,
  status: "ACTIVE",
  dateOfBirth: "",
  expiryDate: null,
};

function RegisterUser() {
  const [data, setData] = useState({
    name: "",
    phoneNumber: "",
    address: "",
    cardNumber: "",
    kbNumber: "",
    userId: 0,
    age: 0,
    status: "ACTIVE",
    dateOfBirth: "",
    expiryDate: null,
  });
  const { userId, status } = useParams();
  const [editMode, setEditMode] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("ACTIVE");
  const [formLabel, setLabel] = useState("Edi User");
  const [isGetLoading, setGetIsLoading] = useState(false);
  const [isPostLoading, setIsPostLoading] = useState(false);
  const [switchOn, setSwitchOn] = useState(false);

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setData((data) => ({
      ...data,
      [id]: value,
    }));
  };

  const getUser = async (id) => {
    setGetIsLoading(true);
    try {
      let res = await get(`/User/GetUsers?userId=${id}`);
      if (res && res.data.length) {
        setData(res.data[0]);
      }
      setGetIsLoading(false);
    } catch (error) {
      console.error("Error fetching data.");
      setGetIsLoading(false);
    }
  };

  useEffect(() => {
    if (+userId) {
      setEditMode(true);
      getUser(userId);
    } else {
      setData(inititlState);
      setEditMode(false);
    }
  }, [userId]);

  useEffect(() => {
    if (status) {
      setData(inititlState);
      setCurrentStatus(status);
    }
  }, [status]);

  useEffect(() => {
    if (+userId === 0) {
      if (currentStatus === "NEW") {
        setLabel("New User");
      } else if (currentStatus === "RENEW") {
        setLabel("Renew User");
      } else {
        setLabel("Register User");
      }
    } else {
      if (currentStatus === "NEW") {
        setLabel("Edit New User");
      } else if (currentStatus === "RENEW") {
        setLabel("Edit Renew User");
      } else {
        setLabel("Edit User");
      }
    }
  }, [currentStatus]);

  const handleClear = () => {
    setData(inititlState);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    data.status = switchOn ? "ACTIVE" : currentStatus;
    console.log(data);
    setIsPostLoading(true);
    try {
      let res = await post("/User/RegisterUser", data);
      if (res && res.response && res.response.status !== 200) {
        let toastEl = document.getElementById("errorToast");
        let toast = new bootstrap.Toast(toastEl, { autohide: false });
        toast.show();
      } else {
        let toastEl = document.getElementById("successToast");
        let toast = new bootstrap.Toast(toastEl, { autohide: false });
        toast.show();
        if (!editMode) {
          handleClear();
        }
        setCurrentStatus("ACTIVE");
      }
      setIsPostLoading(false);
    } catch (error) {
      console.error("Error fetching data.");
      setIsPostLoading(false);
    }
  };

  const handleSwitch = (e) => {
    var checked = e.target.checked;
    setSwitchOn(checked);
  };

  return (
    <>
      <div class="text-center pt-5">
        <h3>{formLabel}</h3>
      </div>
      {editMode && currentStatus !== "ACTIVE" ? (
        <div class="form-switch container pl-5 pt-3">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckChecked"
            onClick={handleSwitch}
            checked={switchOn}
          />
          <label class="form-check-label" for="flexSwitchCheckChecked">
            Want to <b>Register</b> this user?
          </label>
        </div>
      ) : (
        ""
      )}
      {isGetLoading ? (
        <div class="mt-5 d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <form class="container p-3 border mt-5" onSubmit={handleSubmit}>
          <div class="form-row">
            <div class="form-group col-md-5">
              <label for="name">Name</label>
              <input
                onChange={handleInputChange}
                type="text"
                class="form-control"
                id="name"
                value={data.name}
                required
              />
            </div>
            <div class="form-group col-md-5">
              <label for="phoneNumber">Phone Number</label>
              <input
                onChange={handleInputChange}
                type="text"
                class="form-control"
                id="phoneNumber"
                value={data.phoneNumber}
                required
              />
            </div>
            <div class="form-group col-md-2">
              <label for="dateOfBirth">Date of birth</label>
              <input
                onChange={handleInputChange}
                id="dateOfBirth"
                type="date"
                class="form-control"
                value={data.dateOfBirth ? data.dateOfBirth.split("T")[0] : ""}
                required
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-5">
              <label for="cardNumber">Card number</label>
              <input
                onChange={handleInputChange}
                type="text"
                class="form-control"
                id="cardNumber"
                value={data.cardNumber}
                required={
                  switchOn ? true : currentStatus === "NEW" ? false : true
                }
                disabled={
                  switchOn ? false : currentStatus === "NEW" ? true : false
                }
              />
            </div>
            <div class="form-group col-md-5">
              <label for="kbNumber">KB number</label>
              <input
                onChange={handleInputChange}
                type="text"
                class="form-control"
                id="kbNumber"
                value={data.kbNumber}
                required
              />
            </div>
            <div class="form-group col-md-2">
              <label for="expiryDate">Expiry date</label>
              <input
                onChange={handleInputChange}
                type="date"
                id="expiryDate"
                class="form-control"
                value={data.expiryDate ? data.expiryDate.split("T")[0] : ""}
                required={
                  switchOn
                    ? true
                    : currentStatus === "RENEW" || currentStatus === "NEW"
                    ? false
                    : true
                }
                disabled={
                  switchOn
                    ? false
                    : currentStatus === "RENEW" || currentStatus === "NEW"
                    ? true
                    : false
                }
              />
            </div>
          </div>
          <div class="form-group">
            <div>
              <label for="address">Address</label>
              <textarea
                onChange={handleInputChange}
                class="form-control"
                id="address"
                value={data.address}
                required
              ></textarea>
            </div>
          </div>
          <div class="button-container">
            <button
              class="btn btn-primary"
              type="submit"
              disabled={isPostLoading ? true : false}
            >
              {isPostLoading ? (
                <span
                  class="spinner-border spinner-border-sm mr-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                ""
              )}
              Save
            </button>
            <button onClick={handleClear} class="btn btn-light ml-3">
              Clear
            </button>
          </div>
        </form>
      )}
      <Toast
        message={`User ${editMode ? "Updated" : "Created"} successfully.`}
        id={"successToast"}
      />
      <Toast message="Error occured." id={"errorToast"} />
    </>
  );
}

export default RegisterUser;
