import React from "react";

const Toast = ({ message, id }) => {
  return (
    <div class="position-fixed bottom-0 end-0 p-3" style={{ zIndex: 11 }}>
      <div
        class={`toast align-items-center text-white ${
          id == "errorToast" ? "bg-danger" : "bg-success"
        } border-2`}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        id={id}
      >
        <div class="d-flex">
          <div class="toast-body">{message}</div>
          <button
            type="button"
            class="btn-close btn-close-white me-2 m-auto"
            data-bs-dismiss="toast"
            aria-label="Close"
          ></button>
        </div>
      </div>
    </div>
  );
};

export default Toast;
