import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../utils/Auth";
import { useNavigate } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
  const navigate = useNavigate();
  const isAuth = useAuth();
  const [hasSession, setUserSession] = useState(false);

  useEffect(() => {
    if (isAuth) {
      const { isLoggedIn } = JSON.parse(isAuth);
      setUserSession(isLoggedIn);
    }
  }, [isAuth]);

  const handleLogout = () => {
    sessionStorage.clear();
    setUserSession(false);
    navigate("/");
  };

  return (
    <nav
      class="navbar navbar-expand-lg navbar-dark pl-2 pr-2"
      style={{ backgroundColor: "rgb(41 88 158)" }}
    >
      <Link class="navbar-brand mr-5" to="/home">
        ಬಿ. ಎಮ್. ಎಸ್. ಸಂಯೋಜಿತ
      </Link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item active">
            <Link class="nav-link" to="/registerUser/0/RENEW">
              Renew User <span class="sr-only">(current)</span>
            </Link>
          </li>
          <li class="nav-item active">
            <Link class="nav-link" to="/registerUser/0/NEW">
              New User <span class="sr-only">(current)</span>
            </Link>
          </li>
          <li class="nav-item active">
            <Link class="nav-link" to="/registerUser/0/ACTIVE">
              Register User <span class="sr-only">(current)</span>
            </Link>
          </li>
          <li class="nav-item active">
            <Link class="nav-link" to="/viewUser">
              View User
            </Link>
          </li>
          <li class="nav-item active">
            <Link class="nav-link" to="/sms">
              SMS
            </Link>
          </li>
        </ul>
        <span class="my-2 my-sm-0 mr-2 text-light">
          <span>+91 7204187673 |</span>
        </span>
        <span class="my-2 my-sm-0 mr-2 text-light">
          <span>bmsofc.honnavar@gmail.com</span>
        </span>
        {hasSession ? (
          <button
            class="btn btn-outline-danger my-2 my-sm-0"
            onClick={handleLogout}
          >
            <Link class="text-light font-weight-bold">Logout</Link>
          </button>
        ) : undefined}
      </div>
    </nav>
  );
}

export default Navbar;
